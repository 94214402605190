import { useAuth } from '@/stores/authStore';

export default defineNuxtRouteMiddleware(() => {
  const store = useAuth();
  const isAuthenticated = store.isAuthenticated;
  const hasUser = store.user;
  if (!isAuthenticated) {
    return navigateTo('/login');
  }

  if (isAuthenticated && !hasUser) {
    store.getProfile();
  }
});
